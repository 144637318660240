import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDaTSg8cvxC8LT_1Ju9K3FV1RHppkfA2nk",
  authDomain: "zegates-web.firebaseapp.com",
  projectId: "zegates-web",
  storageBucket: "zegates-web.appspot.com",
  messagingSenderId: "276876378811",
  appId: "1:276876378811:web:ff9c11a2b4c4b0cb905f9e",
  measurementId: "G-78MXCD1TPG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
if (process.env === 'debug') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}


class ContactRepository {
  initialized = false;

  initialize = () => {
    if (!this.initialized) {
      initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider('6Ld4g6kmAAAAAAjZ0_-BvDun4AZb6ZGoRoG7j-iC'),
        isTokenAutoRefreshEnabled: true
      });
      this.initialized = true;
    }
  }

  sendRequest = async (data) => {
    this.initialize();
    const yourCallableFunction = httpsCallable(
      getFunctions(),
      "on_message",
      { limitedUseAppCheckTokens: true },
    );
    await yourCallableFunction(data);
  }

  sendEmail = async () => {
    
  }



}

export default new ContactRepository();